
.minmax{
    font-size: 13px;
    margin-bottom: -.5%;
    margin-top: -.5%;
    border: 1px solid black;
    
    
}
.avgBox{
  margin-left: 35%;
   margin-right: 35%;
    text-align: left;
    margin-bottom: 2%;
    
}

.legend {
    border: 1px solid black;
    padding: 10px;
    width: 160px;
    font-size: 13px;
    
}
.legendRow{
    margin-bottom: 15%;
}
.redBox{
    background-color: rgb(230, 72, 72);
    height: 10px;
    width: 75px;
    margin-bottom: 10px;
    text-align: center;
}
.yellowBox{
    background-color:#d2df1e;
    height: 10px;
    width: 75px;
    text-align: center;
}
.greenBox{
    background-color:rgb(67, 170, 67);
    height: 10px;
    width: 75px;
    text-align: center;
}
