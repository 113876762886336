.areagreen{
background-color: #32967a;
display: inline-block;
margin: 2%;
padding: 10px 5px 0px 5px;
font-size: 18px;
color: white;
width: 140px;

}
.areablue{
    background-color: #2e6ea6;
    display: inline-block;
    margin: 2%;
    padding: 10px 5px 0px 5px;
    font-size: 18px;
    color: white;
    width: 140px;
    
    
    }

    .hr{
        height: 5px;
    }
    .tabled{
        width: 28%;
        text-align: center;
        padding-bottom: 2%;
    }
    .scorecard{
        background-color: #439c4f;
        padding:10%;
        color:white;
    }
    .arear{
        background-color: #528fba;
        padding:5%;
        color:white;
    }